interface IntuitionLogicStateChipProps {
    state?: string;
}

export function IntuitionLogicStateChip({ state }: IntuitionLogicStateChipProps) {
    const logicState = state?.toLowerCase();
    switch (logicState) {
        case 'running':
            return <i className="text-success fs-3 bi bi-play-circle-fill"></i>;

        case 'paused':
            return <i className="text-danger fs-3 bi bi-pause-circle-fill"></i>;

        case 'good_night_mode':
            return <i className="text-secondary fs-3 bi bi-moon-fill"></i>;

        default:
            return <i className="text-muted fs-3 bi bi-question-circle-fill"></i>;
    }
}
