import { useAxiosEx } from '../../DataLoader';

export interface SapMasterDataBase {
    ItemCode: string;
    ItemName: string;
}

interface SapMasterData extends SapMasterDataBase {
    [k: string]: string;
}

export function useMasterDataFlatPurchaseItemsOnly(src: string) {
    const query = encodeURIComponent("AND T0.PrchseItem = 'Y'");
    const url = `/api-sap/db/${src}/master-data-flat?extraFilter=${query}`;
    return useAxiosEx<SapMasterData[], any, any>(url);
}

export function useMasterDataFlatSalesItemsOnly(src: string) {
    const query = encodeURIComponent("AND T0.SellItem = 'Y'");
    const url = `/api-sap/db/${src}/master-data-flat?extraFilter=${query}`;
    return useAxiosEx<SapMasterData[], any, any>(url);
}

export function useMasterDataFlatinventoryItemsOnly(src: string) {
    const query = encodeURIComponent("AND T0.InvntItem = 'Y'");
    const url = `/api-sap/db/${src}/master-data-flat?extraFilter=${query}`;
    return useAxiosEx<SapMasterData[], any, any>(url);
}

export function useMasterDataFlat(src: string) {
    const url = `/api-sap/db/${src}/master-data-flat`;
    return useAxiosEx<SapMasterData[]>(url);
}

export function useProjectStock(src?: string, projectCode?: string) {
    const url = `/api-sap/db/${src}/project-stock?projectCode=${projectCode}`;
    const manual = projectCode == null || src == null;
    return useAxiosEx<SapMasterData[]>(url, { manual });
}

export interface ProjectStockWithMinMax extends SapMasterDataBase {
    SPAREPARTS: number;
    QUARANTINE: number;
    SCRAP: number;
    SparePartsMin: number;
    SparePartsMax: number;
    SPAREPARTS_BIN: number;
}

export function useProjectStockWithMinMax(src?: string, projectCode?: string) {
    const url = `/api-sap/db/${src}/project-stock-with-minmax?projectCode=${projectCode}`;
    const manual = projectCode == null || src == null;
    return useAxiosEx<ProjectStockWithMinMax[]>(url, { manual });
}
