import { useEffect, useRef } from 'react';

type Callback = () => void;

export function useInterval(callback: Callback, intervalMiliseconds?: number) {
    const savedCallback = useRef<Callback>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current && savedCallback.current();
        }
        if (intervalMiliseconds != null) {
            let id = setInterval(tick, intervalMiliseconds);
            return () => clearInterval(id);
        }
    }, [intervalMiliseconds]);
}
