import { useAxiosEx } from '../DataLoader';
import { type ProjectDataSet } from '../Types/types';
import { useInterval } from './useInterval';
import { ProjectAllTimeStats, ProjectDataOptions, STATS_URLS } from '@bp/utils';
import { DataProviderResult } from '@bp/intuition';
import React from 'react';

const ONE_MINUTE = 1 * 60 * 1000;

const ALL_STATS_URLS = [
    'ff-monthly-performance-last-year' as STATS_URLS,
    'ff-median-per-orderline-per-day' as STATS_URLS,
    'ff-order-structure-per-day' as STATS_URLS,
    'ff-hourly-performance-last-week' as STATS_URLS,
    'ff-backlog-per-days' as STATS_URLS,
    'ff-orderlines-per-picker-per-day' as STATS_URLS,
    'ff-backlog-per-hour' as STATS_URLS,
    'ff-orderlines-freqency-per-day' as STATS_URLS,
    'ff-sectors-occupancy' as STATS_URLS,
    'ff-weekly-performance-last-year' as STATS_URLS,
    'job-duration-per-day' as STATS_URLS,
    'ff-picks-freqency-per-day' as STATS_URLS,
    'ff-backlog-health' as STATS_URLS,
    'picking-position' as STATS_URLS,
    'carry-positions-heatmap-today' as STATS_URLS,

    'dsp-outputs-per-month-ex' as STATS_URLS,
    'dsp-outputs-per-week-ex' as STATS_URLS,
    'dsp-inputs-per-hour-per-day-ex' as STATS_URLS,
    'dsp-outputs-per-hour-per-day-ex' as STATS_URLS,
    'dsp-inputs-per-day-ex' as STATS_URLS,
    'dsp-outputs-per-day-ex' as STATS_URLS,
    'dsp-indirect-vs-direct-per-day-ex' as STATS_URLS,
    'dsp-median-per-day-ex' as STATS_URLS,
    'dsp-backlog-by-hour-ex' as STATS_URLS,
    'dsp-ten-minute-performance' as STATS_URLS,
    'racks-occupancy-ex' as STATS_URLS,
    'racks-occupancy' as STATS_URLS,
    'dsp-totes-per-inputs-per-day-ex' as STATS_URLS,
    'dsp-totes-in-stash-avg-age-ex' as STATS_URLS,
    'dsp-jobs-median-ex' as STATS_URLS,
    'storage-info' as STATS_URLS,

    'fivemin_performance' as STATS_URLS,
    'alltime-stats' as STATS_URLS,

    'errors-load-failure-rate-last-month' as STATS_URLS,
    'errors-unload-failure-rate-last-month' as STATS_URLS,
    'errors-failures-load-per-height-per-day' as STATS_URLS,
    'errors-failures-unload-per-height-per-day' as STATS_URLS,
    'errors-failure-load-on-inputs-per-runner-per-day' as STATS_URLS,
    'errors-failure-load-on-outputs-per-runner-per-day' as STATS_URLS,
    'errors-failure-unload-by-position-per-day' as STATS_URLS,
    'errors-failure-load-by-position-per-day' as STATS_URLS,
    'errors-pick-errors-per-day' as STATS_URLS,
    'errors-generalized-last-24h' as STATS_URLS,
    'errors-mean-time-between-failures-last-month' as STATS_URLS,
    'deployments' as STATS_URLS,
    'errorstats-30last-days' as STATS_URLS
];

const ALL_EXECUTIVE_STATS_URLS = [
    'ff-order-structure-per-day' as STATS_URLS,
    'ff-median-per-orderline-per-day' as STATS_URLS,
    'ff-hourly-performance-last-week' as STATS_URLS,
    'ff-backlog-per-days' as STATS_URLS,
    'ff-backlog-per-hour' as STATS_URLS,

    'dsp-inputs-per-hour-per-day-ex' as STATS_URLS,
    'dsp-outputs-per-hour-per-day-ex' as STATS_URLS,
    'dsp-inputs-per-day-ex' as STATS_URLS,
    'dsp-outputs-per-day-ex' as STATS_URLS,
    'dsp-indirect-vs-direct-per-day-ex' as STATS_URLS,
    'dsp-median-per-day-ex' as STATS_URLS,
    'dsp-backlog-by-hour-ex' as STATS_URLS,
    'dsp-ten-minute-performance' as STATS_URLS,

    'racks-occupancy-ex' as STATS_URLS,
    'racks-occupancy' as STATS_URLS,
    'dsp-totes-per-inputs-per-day-ex' as STATS_URLS,
    'dsp-jobs-median-ex' as STATS_URLS,
    'storage-info' as STATS_URLS,

    'fivemin_performance' as STATS_URLS,
    'alltime-stats' as STATS_URLS,

    'errors-pick-errors-per-day' as STATS_URLS,
    'errors-mean-time-between-failures-last-month' as STATS_URLS,
    'deployments' as STATS_URLS,
    'errorstats-30last-days' as STATS_URLS
];

const POST_DATA: ProjectDataOptions = {
    includeAssets: true,
    includeOccupancy: true,
    includeStats: ALL_STATS_URLS
};

const POST_ERROR_STATS_DATA: ProjectDataOptions = {
    includeStats: ['errors-30last-days' as STATS_URLS, 'deployments' as STATS_URLS]
};

const POST_DATA_EXECUTIVE: ProjectDataOptions = {
    includeOccupancy: true,
    includeStats: ALL_EXECUTIVE_STATS_URLS
};

export function useProjectErrors(projectCode: string, refreshTimeoutSecs = ONE_MINUTE) {
    const url = `/api-jira/projects/${projectCode}/data`;
    const [{ data, loading, error }, refetch] = useAxiosEx<ProjectDataSet>({ url, method: 'GET', params: POST_ERROR_STATS_DATA });

    useInterval(() => {
        void refetch();
    }, refreshTimeoutSecs);

    return [{ data, loading, error }, refetch] as const;
}

export function useProjectStats(projectCode?: string, refreshTimeoutSecs = ONE_MINUTE, executive: boolean = false) {
    const [{ data, loading, error }, refetch] = useAxiosEx<ProjectDataSet>(
        {
            url: `/api-jira/projects/${projectCode}/data`,
            method: 'GET',
            params: executive ? POST_DATA_EXECUTIVE : POST_DATA
        },
        { manual: projectCode == null }
    );

    useInterval(() => {
        void refetch();
    }, refreshTimeoutSecs);

    const dataProcessed = React.useMemo(() => {
        data?.stats?.forEach((stat) => {
            stat?.query_result?.length > 0 &&
                stat?.query_result?.forEach((x) => {
                    if (x.hour !== undefined && x.hour_str === undefined) {
                        x.hour_str = `${x.hour as number}`;
                    }
                });
        });
        return data;
    }, [data]);

    return [{ data: dataProcessed, loading, error }, refetch] as const;
}

export function useProjectStatsEx(projectCode?: string, refreshTimeoutSecs = ONE_MINUTE) {
    const d: ProjectDataOptions = {
        includeLast24hoursPerformance: true
    };

    const [{ data, loading, error }, refetch] = useAxiosEx<ProjectDataSet>(
        {
            url: `/api-jira/projects/${projectCode}/data`,
            method: 'GET',
            params: d
        },
        { manual: projectCode == null }
    );

    useInterval(() => {
        void refetch();
    }, refreshTimeoutSecs);

    const dataProcessed = React.useMemo(() => {
        data?.stats?.forEach((stat) => {
            stat?.query_result?.length > 0 &&
                stat?.query_result?.forEach((x) => {
                    if (x.hour !== undefined && x.hour_str === undefined) {
                        x.hour_str = `${x.hour as number}`;
                    }
                });
        });
        return data;
    }, [data]);

    return [{ data: dataProcessed, loading, error }, refetch] as const;
}

export function useProjectAllTimeStats(refreshTimeout = ONE_MINUTE) {
    const url = `/api-jira/projects/alltime-stats`;
    const [{ data, loading, error }, refetch] = useAxiosEx<DataProviderResult<ProjectAllTimeStats>>({ url });

    useInterval(() => {
        void refetch();
    }, refreshTimeout);

    return [{ data: data?.query_result?.[0], loading, error }] as const;
}
