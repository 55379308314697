import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { userAccessListPCP, userAccessListPPMC } from '../config/config';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function MenuItemsBrightpick({ frontendData, user }: { frontendData: any | null; user: any }): JSX.Element {
    return (
        <>
            <NavDropdown title="Products">
                <NavDropdown.Item as={NavLink} eventKey={'101'} to="/product/list">
                    Product Tree
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'102'} to="/product/compatibility-matrix">
                    Compatibility Matrix
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} eventKey={'103'} to="/product/runners">
                    <strong>Runners</strong>
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'104'} to="/product/amrs">
                    AMRs
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'105'} to="/product/addons">
                    Addons
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'106'} to="/product/pickers">
                    Pickers
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'107'} to="/product/charging-docks">
                    Charging Docks
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'108'} to="/product/intuitions">
                    Intuitions
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Projects">
                <NavDropdown.Item as={NavLink} eventKey={'121'} to="/project/list">
                    Project List
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'122'} to="/project/board">
                    Projects Board
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'123'} to="/project/map">
                    Projects Map
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'124'} to="/project/charts/fulfillments">
                    Projects Charts Fulfillments
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'1241'} to="/project/charts/fulfillments-exec">
                    Projects Charts Fulfillments Executive
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'1242'} to="/project/charts/dispatches-exec">
                    Projects Charts Dispatches Executive
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'125'} to="/project/charts/dispatches">
                    Projects Charts Dispatches
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'128'} to="/sap/live/spare-parts-buffer">
                    Spare Parts Buffer
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'130'} to="/sap/live-us/spare-parts-buffer">
                    Spare Parts Buffer (US)
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {frontendData?.projects?.map((project: any, index: number) => {
                    const pcwd = project?.projectCode?.toLowerCase().replace('-', '');
                    return (
                        <NavDropdown.Item key={project.projectCode} as={NavLink} eventKey={`${131 + index}`} to={`/project/${pcwd as string}`}>
                            <span className="font-monospace" style={{ display: 'inline-block', width: '80px' }}>
                                {project.projectCode}
                            </span>
                            {project.summary}
                        </NavDropdown.Item>
                    );
                })}
            </NavDropdown>
            <Nav.Link as={NavLink} to="/product/runners">
                Runners
            </Nav.Link>
            <NavDropdown title="Commissioning">
                <NavDropdown.Item as={NavLink} eventKey={'151'} to="/mm/pcp/matrix/runners">
                    Runners only
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'154'} to="/mm/pcp/matrix/amrs">
                    AMR only
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'155'} to="/mm/pcp/matrix/addons">
                    Addons only
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'152'} to="/mm/pcp/matrix/all">
                    All products
                </NavDropdown.Item>
                {userAccessListPCP.includes(user?.email) && (
                    <React.Fragment>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={NavLink} eventKey={'155'} to="/mm/pcp/manager">
                            Manager
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} eventKey={'156'} to="/mm/pcp/charts">
                            Charts
                        </NavDropdown.Item>
                    </React.Fragment>
                )}
            </NavDropdown>
            <NavDropdown title="PPMC">
                <NavDropdown.Item as={NavLink} eventKey={'162'} to="/mm/ppmc/matrix/all">
                    All products
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'163'} to="/mm/ppmc/matrix/amrs">
                    AMR only
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'164'} to="/mm/ppmc/matrix/addons">
                    Addons only
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={'161'} to="/mm/ppmc/matrix/runners">
                    Runners only
                </NavDropdown.Item>
                {userAccessListPPMC.includes(user?.email) && (
                    <React.Fragment>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={NavLink} eventKey={'165'} to="/mm/ppmc/manager">
                            Manager
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} eventKey={'166'} to="/mm/ppmc/charts">
                            Charts
                        </NavDropdown.Item>
                    </React.Fragment>
                )}
            </NavDropdown>
        </>
    );
}
