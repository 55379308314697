import React, { ChangeEventHandler } from 'react';
import { Form, FormSelectProps } from 'react-bootstrap';
import { useSapSeries } from '../../../Hooks/SAP/useSapSeriesPurchaseRequests';
import { withFormController } from '../../HOC/withFormController';

interface SAPDocumentSeriesDropdownProps {
    src: string;
    value?: number;
    onChangeForm?: ChangeEventHandler<HTMLSelectElement>;
    sapB1DocumentType: number;
}

export function SAPDocumentSeriesDropdown(props: SAPDocumentSeriesDropdownProps & Omit<FormSelectProps, 'OnChange'>): JSX.Element {
    const { src, value, onChangeForm, sapB1DocumentType, ...rest } = props;
    const [{ data: series, loading: seriesLoading }] = useSapSeries(src, sapB1DocumentType);

    return (
        <Form.Select onChange={onChangeForm} {...rest} disabled={props.disabled || seriesLoading} value={seriesLoading ? 'Loading ...' : props.value}>
            <option key={0} value={seriesLoading ? 'Loading ...' : 'Select series ...'}></option>
            {series?.map((x, idx) => (
                <option key={idx} value={x.Series}>
                    {x.SeriesName} - {x.Remark}
                </option>
            ))}
        </Form.Select>
    );
}

export const SAPDocumentSeriesDropdownFormControl = withFormController(SAPDocumentSeriesDropdown);
