import { Control, Controller } from 'react-hook-form';

interface WithFormControllerProps {
    control: Control<any, any>;
    name: string;
}

export function withFormController<T>(Component: React.ComponentType<T>) {
    return function ProjectListTypeaheadController(props: T & WithFormControllerProps) {
        return (
            <Controller
                control={props.control}
                name={props.name}
                render={(renderProps) => (
                    <Component
                        isInvalid={renderProps.fieldState.invalid}
                        disabled={renderProps.formState.isLoading || renderProps.formState.isSubmitting}
                        onChangeForm={renderProps.field.onChange}
                        onBlur={renderProps.field.onBlur}
                        value={renderProps.field.value}
                        {...props}
                    />
                )}
            />
        );
    };
}
