import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

interface IProps {
    isSubmitting: boolean;
}

const SubmitButtonWithSpinner: React.FC<IProps & ButtonProps> = ({ isSubmitting, ...props }) => {
    const text = isSubmitting ? 'Submitting ...' : 'Submit';
    return (
        <Button type="submit" {...props} style={{ minWidth: 140 }}>
            {isSubmitting && <span className="spinner-grow spinner-grow-sm me-2"></span>}
            {text}
        </Button>
    );
};

export default SubmitButtonWithSpinner;
