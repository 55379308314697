/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// import React from 'react';
import * as utils from './utils';

const filter_empty = '- empty -';

export function keyCounts(values: any, data: any, calcParams: any) {
    const result: any = {};
    values?.forEach((item: any) => {
        if (!item) return;
        let value = item;
        if (calcParams?.field !== undefined) {
            const parts = calcParams?.field.split('.');
            if (parts.length === 1) value = item[parts[0]];
            if (parts.length === 2) {
                value = item[parts[0]][parts[1]];
            }
        }
        if (value === undefined) return;
        if (result[value] === undefined) result[value] = 0;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        result[value] += 1;
    });
    return result;
}

export function multiSelectHeaderFilter(cell: any, onRendered: any, success: any, cancel: any, editorParams: any): any {
    const getSelectedValues = (multiSelect: any) => {
        const result = [];
        const options = multiSelect && multiSelect.options;
        let opt;
        for (let i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];
            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    };

    const onChange = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const editor = document.getElementById('headerSelector_' + editorParams.path);
        const values = getSelectedValues(editor);
        success({ list: values });
    };

    // const existing = document.getElementById('headerSelector_' + editorParams.path) as any;
    // if (existing) return;
    const div = document.createElement('div') as any;
    const select = document.createElement('select') as any;
    select.multiple = 'multiple';
    select.id = 'headerSelector_' + editorParams.path;
    select.class = 'chosen-select';
    select.style = 'width: 100%; Border: none; height:145px; overflow-y:auto;font-size: 11px;';

    editorParams.values?.sort();
    let options = '';
    editorParams.values?.forEach((item2: any) => {
        const strChecked = " selected='selected'";
        // if (item2 == 'New') strChecked = '';
        options += "<option id='" + item2 + "' value='" + item2 + "' " + strChecked + '>' + item2 + '</option>';
    });
    select.innerHTML = options;

    select.addEventListener('change', onChange);
    div.appendChild(select);

    return div;
}

export function multiSelectHeaderFilterFunction(headerValue: any, rowValue: any, rowData: any, filterParams: any) {
    if (rowData === undefined || rowData == null) return false;
    let valueString = '';
    try {
        valueString = String(utils.ObjectByString(rowData, filterParams?.path));
    } catch (error) {
        valueString = String(utils.ObjectByString(rowData, filterParams?.path));
    }
    const includes = headerValue.list.includes(valueString) || ((valueString === 'null' || valueString === 'undefined') && headerValue.list.includes(filter_empty));
    return includes;
}

export function customHeaderFilter(headerValue: any, rowValue: any, rowData: any, filterParams: any): any {
    // headerValue - the value of the header filter element
    // rowValue - the value of the column in this row
    // rowData - the data for the row being filtered
    // filterParams - params object passed to the headerFilterFuncParams property
    if (!filterParams?.field || !filterParams?.field) return;

    const first = filterParams?.field.charAt(0);
    let valueString = '';
    if (first === '.') {
        if (!rowValue) return false;
        valueString = String(utils.ObjectByString(rowValue, filterParams?.field.substring(1)));
    } else {
        if (!rowData) return false;
        valueString = String(utils.ObjectByString(rowData, filterParams?.field));
    }

    const firstHeader = headerValue.charAt(0);
    if (firstHeader === '!') {
        headerValue = headerValue.substring(1);
    }
    let pass = false;
    if (headerValue.toUpperCase() === '- EMPTY -' || headerValue.toUpperCase() === 'EMPTY' || headerValue.toUpperCase() === 'NULL') {
        pass = valueString === 'undefined' || valueString === undefined || valueString == null || valueString === '' || valueString.length <= 0;
    } else {
        pass = valueString.toUpperCase().includes(headerValue.toUpperCase());
    }

    return firstHeader === '!' ? !pass : pass;
}

export function getTabulatorColumnsFlat(columns: any[]): any[] {
    const result: any[] = [];
    columns.forEach((column) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (column.columns) {
            result.push(...column.columns);
        } else {
            result.push(column);
        }
    });
    return result;
}

export function getColumnsFromFirstRow(rowData: any[]): any[] {
    if (rowData?.length === 0) return [];
    const firstRow = rowData[0];
    const columns: any[] = [];
    Object.keys(firstRow).forEach((key: any) => {
        columns.push({ field: key });
    });
    return columns;
}

export function getHeaderFilterValues(columns: any[], rowData: any[]): void {
    const columnsFlat = getTabulatorColumnsFlat(columns);
    const columnsHeaderFilters = columnsFlat.filter((x) => x.headerFilter === multiSelectHeaderFilter);
    columnsHeaderFilters.forEach((x) => (x.headerFilterParams.values = [filter_empty]));
    rowData.forEach((row) => {
        columnsHeaderFilters.forEach((item) => {
            let value = utils.ObjectByString(row, item.headerFilterParams.path);
            if (value === true) {
                value = 'true';
            }
            if (value === false) {
                value = 'false';
            }
            if (value && value.length > 0 && item.headerFilterParams.values.indexOf(value) < 0) item.headerFilterParams.values.push(value);
        });
    });
}

function findInForest(forest: any, code: any): any {
    if (Array.isArray(forest)) {
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const idx in forest) {
            const tree = forest[idx];
            const res = findInForest(tree, code);
            if (res !== null) return res;
        }
    } else {
        const tree = forest;
        if (tree._Path === code) return tree;
        if (tree._children !== null && Array.isArray(tree._children)) {
            const res = findInForest(tree._children, code);
            if (res !== null) return res;
        }
    }
    return null;
}

export function unFlattenForest(flatForest: any): any {
    const backlog = [];
    const result = [];
    for (const node of flatForest) {
        backlog.push(node);
    }

    // step 1 - only root nodes
    for (const node of flatForest) {
        if (node._ParentPath === node._Path || findInForest(flatForest, node._ParentPath) === null) result.push(node);
    }

    // eslint-disable-next-line @typescript-eslint/no-for-in-array

    for (let idx = 0; idx < result.length; idx++) {
        const node = result[idx];
        let bidx = 0;
        while (bidx < backlog.length) {
            if (backlog[bidx]._Path === node._Path) {
                backlog.splice(bidx, 1);
            } else {
                bidx++;
            }
        }
    }

    // step 2 - rest of the items
    while (backlog.length > 0) {
        const node = backlog[0];
        backlog.splice(0, 1);

        let father = findInForest(backlog, node._ParentPath);
        if (father == null) father = findInForest(result, node._ParentPath);

        if (father !== null) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, no-prototype-builtins
            if (!father.hasOwnProperty('_children')) father._children = [];
            father._children.push(node);
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        } else console.log('Unable to find father ' + node._ParentPath);
    }

    return result;
}
