import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ButtonHome: React.FC<ButtonProps> = ({ ...props }) => {
    const navigate = useNavigate();
    return (
        <Button variant="outline-secondary" {...props} style={{ minWidth: 140 }} onClick={() => navigate('/')}>
            Home
        </Button>
    );
};

export default ButtonHome;
