import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';

export default function SAPMenuItems({ dbName, isBrightpick, eventKeyOffset }: { dbName: string; isBrightpick: boolean; eventKeyOffset: number }): JSX.Element {
    const isLive = dbName === 'live';
    const isTest = !isLive;
    return (
        <>
            <NavDropdown title={`SAP ${isTest ? '(' + dbName + ')' : ''}`}>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 1} to={`/sap/${dbName}/approval-pending`}>
                    Approval Pending
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 2} to={`/sap/${dbName}/approval-requests`}>
                    Approval Requests
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 10} to={`/sap/${dbName}/inventory-transfer-requests-recent`}>
                    Inventory Transfer Requests Recent
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 4} to={`/sap/${dbName}/purchase-requests`}>
                    Purchase Requests Recent
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 5} to={`/sap/${dbName}/purchase-orders-recent`}>
                    Purchase Orders Recent
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 6} to={`/sap/${dbName}/purchase-advanceinvoices-recent`}>
                    Purchase Advance Invoices Recent
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 7} to={`/sap/${dbName}/sales-orders-recent`}>
                    Sales Orders Recent
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 8} to={`/sap/${dbName}/manufacturing-orders-recent`}>
                    Manufacturing Orders Recent
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 9} to={`/sap/${dbName}/manufacturing-orders-closed`}>
                    Manufacturing Orders Closed
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Header>Master Data Views</NavDropdown.Header>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 11} to={`/sap/${dbName}/master-data-tree?ItmsGrpNam=Prod`}>
                    MasterData Tree of Products
                </NavDropdown.Item>
                {isBrightpick && (
                    <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 12} to={`/sap/${dbName}/master-data-tree?ItmsGrpNam=Subassembly`}>
                        MasterData Tree of Subassemblies
                    </NavDropdown.Item>
                )}
                {!isBrightpick && (
                    <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 13} to={`/sap/${dbName}/master-data-tree?ItmsGrpNam=Units`}>
                        MasterData Tree of Units
                    </NavDropdown.Item>
                )}
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 14} to={`/sap/${dbName}/master-data-flat`}>
                    MasterData Flat
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 15} to={`/sap/${dbName}/3d-prints-tracker`}>
                    3D Prints Tracker
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 16} to={`/sap/${dbName}/procurement-tracker`}>
                    Procurement Tracker
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 17} to={`/sap/${dbName}/manufacturing-tracker`}>
                    Manufacturing Tracker
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 18} to={`/sap/${dbName}/itemcode-bom-tracer`}>
                    ItemCode BOM Tracer
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 19} to={`/sap/${dbName}/cost-centers`}>
                    Cost Center List
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 20} to={`/sap/${dbName}/manufacturers`}>
                    Manufacturers
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 21} to={`/sap/${dbName}/stock-bins`}>
                    Stock Bins
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey={eventKeyOffset + 22} to={`/sap/${dbName}/financial-assets`}>
                    Assets
                </NavDropdown.Item>
            </NavDropdown>
        </>
    );
}
