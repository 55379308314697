import { useAxiosEx } from '../../DataLoader';

export interface SapEmployee {
    EmployeeId: number;
    FullName: string;
    Email: string;
    SalesPersonCode: number;
}

export function useSapEmployees(src?: string) {
    const url = `/api-sap/db/${src}/employee-data`;
    const manual = src == null || src === '';
    return useAxiosEx<SapEmployee[]>(url, { manual });
}
