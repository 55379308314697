import React from 'react';
import { SAPDocumentHeader, formatSapValue, sapFieldNameToLabel } from './SAPTypes';
import Stack from 'react-bootstrap/esm/Stack';
import { ShipmentTrackerChip } from '../../Common/ShipmentTrackerChip';
import { Col, Row } from 'react-bootstrap';

interface SAPDocumentHeaderProps {
    header?: SAPDocumentHeader;
}

export function SAPDocHeader(props: SAPDocumentHeaderProps) {
    function NameValueRow({ name, Element }: { name: keyof SAPDocumentHeader; Element?: React.FC<any> }) {
        return (
            <Stack direction="horizontal" gap={3}>
                <div style={{ minWidth: '140px' }}>
                    <b>{sapFieldNameToLabel(name)}</b>
                </div>
                <div>{Element != null ? <Element {...props} /> : formatSapValue(name, props.header?.[name])}</div>
            </Stack>
        );
    }

    const Shipment = (props: any) => <ShipmentTrackerChip trackingId={props.header?.U_PHO_TrackInfo} />;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Stack direction="vertical" gap={1}>
                        <NameValueRow name="CardCode" />
                        <NameValueRow name="CardName" />
                        <NameValueRow name="NumAtCard" />
                        <NameValueRow name="U_PHO_POSubj" />
                    </Stack>
                </Col>
                <Col>
                    <Stack direction="vertical" gap={1}>
                        <NameValueRow name="DocNum" />
                        <NameValueRow name="U_PHO_DeliveryStat" />
                        <NameValueRow name="U_PHO_TRNO" Element={Shipment} />
                    </Stack>
                </Col>
            </Row>
            <br />
            <Row>
                <NameValueRow name="Comments" />
            </Row>
        </React.Fragment>
    );
}
