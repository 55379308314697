import React from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import { SapEmployee, useSapEmployees } from '../../../Hooks/SAP/useSapEmployees';

import { TypedTypeahead, TypedTypeaheadProps } from './TypedTypeahead';
import { withFormController } from '../../HOC/withFormController';

interface SapEmployeeTypeaheadProps extends Omit<TypedTypeaheadProps<SapEmployee>, 'options'> {
    src?: string;
    value?: unknown | null;
    projectsFilter?: (project: SapEmployee) => boolean;
    returnField?: keyof SapEmployee;
    onChangeForm?: (selected: unknown | null) => void;
}

export function SapEmployeeTypeahead2({ src, value, returnField, ...rest }: SapEmployeeTypeaheadProps) {
    const [{ data, loading }] = useSapEmployees(src);

    const dataFiltered = React.useMemo(() => {
        return data?.filter((x) => x[returnField ?? 'EmployeeId'] != null);
    }, [data, returnField]);

    const selectedByValue = value ? dataFiltered?.filter((x) => x[returnField ?? 'EmployeeId'] === value) : null;
    const toSelect = selectedByValue && selectedByValue?.length > 0 ? selectedByValue : [];
    const [selected, setSelected] = React.useState<SapEmployee[]>(toSelect);

    return (
        <TypedTypeahead<SapEmployee>
            selected={selected}
            clearButton
            id="sap-employee-selector"
            filterBy={['FullName', 'email']}
            isLoading={loading}
            labelKey={(option) => `${option.FullName} - ${option.Email}`}
            minLength={0}
            inputProps={{ spellCheck: false }}
            options={dataFiltered ?? []}
            renderMenuItemChildren={(option, { text }) => (
                <Highlighter highlightClassName="HighlighterMark" search={text}>
                    {option.FullName}
                </Highlighter>
            )}
            onChange={(selected) => {
                setSelected(selected);
                rest.onChangeForm?.(selected.length > 0 ? selected[0][returnField ?? 'EmployeeId'] : null);
            }}
            {...rest}
        />
    );
}

export const SapEmployeeTypeaheadFormControl = withFormController(SapEmployeeTypeahead2);
