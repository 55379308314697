import { ProjectSemaphore } from '@bp/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { forwardRef } from 'react';

interface ProjectSemaphoreProps {
    value: ProjectSemaphore;
}

export function ProjectSemaphoreChip({ value }: ProjectSemaphoreProps) {
    const classNameBase = 'fs-3 bi me-2';

    const Icon = forwardRef<HTMLSpanElement, { value: ProjectSemaphore }>(({ value }, ref) => {
        switch (value.value) {
            case 'GREEN':
                return <i ref={ref} className={'text-success bi-check-circle-fill ' + classNameBase}></i>;
            case 'YELLOW':
                return <i ref={ref} className={'text-warning  bi-exclamation-triangle-fill ' + classNameBase}></i>;
            case 'RED':
                return <i ref={ref} className={'text-danger bi-exclamation-octagon-fill ' + classNameBase}></i>;
            case 'BLACK':
                return <i ref={ref} className={'text-dark bi-exclamation-octagon-fill ' + classNameBase}></i>;
            default:
                return <></>;
        }
    });

    return (
        <OverlayTrigger overlay={<Tooltip>TEST</Tooltip>}>
            <Icon value={value} />
        </OverlayTrigger>
    );
}
