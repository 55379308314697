import React from 'react';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';

interface AvatarProps extends React.ComponentPropsWithoutRef<'div'> {
    displayName?: string;
    email?: string;
    avatarUrl?: string;
    tooltipAdditional?: string;
    showUnknownIfNull?: boolean;
}

export function Avatar({ displayName, email, avatarUrl, tooltipAdditional, showUnknownIfNull, ...props }: AvatarProps): JSX.Element {
    const colors = ['#FFB300', '#FF4081', '#7C4DFF', '#536DFE', '#40C4FF', '#00BFA5', '#64DD17', '#AEEA00', '#FFD600'];
    const initials =
        displayName
            ?.split(' ')
            .map((n) => n[0])
            .join('')
            .toLocaleUpperCase() ?? 'N/A';
    const charCodeSum = initials.charCodeAt(0) + (initials.charCodeAt(1) || 0);
    const color = colors[charCodeSum % colors.length];

    if (!displayName && !email && !avatarUrl && !showUnknownIfNull) return <></>;

    return (
        <OverlayTrigger
            overlay={
                email ? (
                    <Tooltip id={`tooltip-${email}`}>
                        {displayName} {tooltipAdditional}
                    </Tooltip>
                ) : (
                    <> </>
                )
            }
        >
            <div className={props.className} style={props.style}>
                <div className={'d-flex align-items-center'} style={{ height: '30px', width: '30px' }}>
                    {avatarUrl ? (
                        <img src={avatarUrl} alt={displayName} className="rounded-circle w-100 h-100" />
                    ) : (
                        <div
                            className="rounded-circle d-flex justify-content-center align-items-center w-100 h-100"
                            style={{ backgroundColor: email ? color : '#808080', color: 'white' }}
                        >
                            {initials}
                        </div>
                    )}
                </div>
            </div>
        </OverlayTrigger>
    );
}
