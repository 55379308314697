import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";

export interface SAPDocumentHeader {
	DocEntry: number;
	DocNum: string;
	CANCELED: string;
	DocStatus: SAPDocStatus;
	ObjType: number;
	DocDate: Date;
	DocDueDate: Date;
	CardCode: string;
	CardName: string;
	NumAtCard: string;
	DocCur: string;
	DocTotal: number;
	DocRate: number;
	U_PHO_DeliveryStat: string;
	U_PHO_TRNO: string;
	U_PHO_POSubj: string;
	Comments: string;
}

export interface SAPDocumentLine {
	DocEntry: number;
	LineNum: number;
	LineStatus: SAPDocStatus;
	ItemCode: string;
	Dscription: string;
	Quantity: number;
	Price: number;
	Currency: string;
	_children?: SAPDocumentLine[];
}

export type SAPDocumentLines = SAPDocumentLine[];

export interface SAPDocument {
	header: SAPDocumentHeader;
	lines: SAPDocumentLines;
}

export enum SAPDocType {
	SalesOrder = 'RDR',
	ARInvoice = 'INV'
}

export enum SAPDocStatus {
	Open = 'O',
	Closed = 'C'
}

export enum SAPDbSource {
	PhotoneoSRO = 'live',
	PhotoneoSRO_Test = 'test',
	PhotoneoInc = 'live-us',

}

//export type SAPDocumentAsQueryResult = Partial<SAPDocumentHeader> & SAPDocumentLine;
// export type SAPDocumentAsQueryResultArray = SAPDocumentAsQueryResult[]
export type SAPHeaderColumns = Record<keyof SAPDocumentHeader, Tabulator.ColumnDefinition>;

export type SAPValue = keyof SAPDocumentHeader | Omit<'_children', keyof SAPDocumentLine>


export function formatSapValue(name: SAPValue, value: any): string {
	// empty value
	if (value == null) return '';

	// dates
	if (name.toLowerCase().includes('date')) {
		value = new Date(value);
	}
	if (value instanceof Date) {
		return value.toDateString();
	}

	// doc status
	if (name === 'DocStatus') {
		return value === 'O' ? 'Open' : value === 'C' ? 'Closed' : 'unknown';
	}

	return value;
}

export function sapFieldNameToLabel(fieldName: keyof SAPDocumentHeader): string {
	switch (fieldName) {
		case 'DocEntry': return 'Document Entry';
		case 'DocNum': return 'Doc Num';
		case 'CANCELED': return 'Canceled';
		case 'DocStatus': return 'Status';
		case 'ObjType': return 'Object Type';
		case 'DocDate': return 'Doc Date';
		case 'DocDueDate': return 'Due Date';
		case 'CardCode': return 'Card Code';
		case 'CardName': return 'Card Name';
		case 'NumAtCard': return 'Refference'
		case 'DocCur': return 'Currency';
		case 'DocTotal': return 'Doc Total';
		case 'DocRate': return 'Rate';
		case 'U_PHO_DeliveryStat': return 'Delivery Status';
		case 'U_PHO_TRNO': return 'Tracking Info';
		case 'U_PHO_POSubj': return 'Subject';
		default: return fieldName;
	}
}
