import React from 'react';
import { Button, Col, Form, FormControlProps, Row } from 'react-bootstrap';
import { withFormController } from '../../HOC/withFormController';
import { DateTime } from 'luxon';

interface DatePickerWithButtonProps {
    onChangeForm?: (value: string) => void;
}

export function DatePickerWithButtonValues({ size, value, onChangeForm, ...rest }: DatePickerWithButtonProps & Omit<FormControlProps, 'onChange'>) {
    const BUTTONS = [14, 30];

    const fromNowDays = (days: number) => DateTime.now().endOf('day').plus({ days: days }).toISO().substring(0, 10);

    const a = DateTime.now().endOf('day');
    const b = DateTime.fromISO(value as string)
        .endOf('day')
        .diff(a, ['days'])
        .toObject();
    const diffString = b.days !== undefined && b.days > 0 ? `${b.days.toFixed(0)} days` : '';
    return (
        <Row>
            <Col xs={5}>
                <Form.Control size={size} type="date" value={value} onChange={(e) => onChangeForm?.(e.target.value)} {...rest}></Form.Control>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
                <span className="flex-grow-1">{diffString}</span>
                {BUTTONS.map((x) => (
                    <Button key={x} className="ms-1" size={size} variant={'secondary'} onClick={(e) => onChangeForm?.(fromNowDays(x))}>
                        {x} days
                    </Button>
                ))}
            </Col>
        </Row>
    );
}

export const DatePickerWithButtonValuesFormControl = withFormController(DatePickerWithButtonValues);
