/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import 'react-tabulator/css/tabulator.min.css';
import { axios } from '../DataLoader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from '../GoogleAuth';
import { TypeOptions, toast } from 'react-toastify';
import { HttpStatusCode } from 'axios';
import { SapB1MasterDataTable } from '../Components/Common/SAPMasterDataTable/MasterDataTable';
import SubmitButtonWithSpinner from '../Components/Common/SpinnerSubmitButton';
import { SAPDocumentSeriesDropdownFormControl } from '../Components/Common/Dropdowns/SAPDocumentSeriesDropdown';
import { SapEmployeeTypeaheadFormControl } from '../Components/Common/Typeaheads/SAPEmployeeTypeahead2';
import { DatePickerWithButtonValuesFormControl } from '../Components/Common/Dropdowns/DatePickerWithButtonValues';
import { SAPSubjectFormControl } from './Common/SAPSubject';
import { SAPCommentFormControl } from './Common/SAPComment';
import { DropzoneFormControl } from '../Components/Common/Dropzone';

const HELP_LINK = 'https://sites.google.com/photoneo.com/sapb1/manualy/nakup/poziadavka-na-objednavku/AppToo';

interface PurchaseReqestFormData {
    Requester: number;
    RequriedDate: string;
    Series: number;
    DocumentsOwner: number;
    ReqType: number;
    SendNotification: string;
    U_PHO_POSubj: string;
    Comments: string;
    DocumentLines: any[];
    // --
    Status: string;
    PaymentMethod?: string | null;
    OrderedOn?: string | null;
    OrderRef?: string | null;
    DeliveredOn?: string | null;
    PurchaseOrder?: string;
    DeliveryNote?: string;
    PaymentReceipt?: string;
}

const in30DaysDateString = new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().substring(0, 10);

const SAPB1_PURCHASE_REQUEST_OBJECT_TYPE = 1470000113;

const validationSchema = Yup.object().shape({
    Requester: Yup.number().required(),
    RequriedDate: Yup.string()
        .test((value) => {
            if (value == null) return false;
            const isInFuture = new Date() < new Date(value);
            return isInFuture;
        })
        .required(),
    SendNotification: Yup.string().required().oneOf(['tYES', 'tNO']),
    ReqType: Yup.number().required().oneOf([171]),
    Series: Yup.number().positive('Series must be set').required(),
    DocumentsOwner: Yup.number().required(),
    U_PHO_POSubj: Yup.string().required().min(8),
    Comments: Yup.string().strict().defined(),
    Status: Yup.string().strict().required().oneOf(['standard', 'ordered', 'delivered']),

    OrderRef: Yup.string().nullable().notRequired(),
    DeliveredOn: Yup.string().nullable().notRequired(),
    OrderedOn: Yup.string().nullable().notRequired(),
    PaymentMethod: Yup.string().nullable().notRequired(),
    // OrderedOn: Yup.string().when('Status', (Status, schema) => ((Status as unknown as string) !== 'standard' ? schema.required() : schema)),
    /* 
    OrderedOn: Yup.string().when('Status', {
        is: ['ordered', 'delivered'],
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired().nullable()
    }),

     OrderRef: Yup.string().when('Status', {
        is: ['ordered', 'delivered'],
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired().nullable()
    }),
   
    DeliveredOn: Yup.string()
        .nullable()
        .when('Status', {
            is: ['delivered'],
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired()
        }),
 */
    DocumentLines: Yup.array()
        .min(1, 'No items specified')
        .of(
            Yup.object().shape({
                ItemCode: Yup.string().required(),
                ItemDescription: Yup.string().required(),
                Quantity: Yup.number().positive().required(),
                U_SFT_UCEL: Yup.string(),
                CostingCode: Yup.string().required(),
                CostingCode2: Yup.string().required(),
                CostingCode3: Yup.string(),
                ProjectCode: Yup.string()
            })
        )
        .required('Required')
});

export default function PurchaseRequestForm2(): JSX.Element {
    const routeParams = useParams();
    const location = useLocation();
    const src = routeParams?.src ?? '';
    const userContext = React.useContext(UserContext);
    const toastId = React.useRef<any>(null);

    const {
        handleSubmit,
        register,
        reset,
        watch,
        control,
        formState: { errors, isSubmitting, isLoading }
    } = useForm<PurchaseReqestFormData>({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            Requester: -1,
            DocumentsOwner: -1,
            Series: -1,
            ReqType: 171,
            Status: 'standard',
            DeliveredOn: null,
            OrderedOn: null,
            OrderRef: null,
            SendNotification: 'tYES',
            RequriedDate: in30DaysDateString,
            DocumentLines: location.state?.data ?? []
        }
    });

    async function onSubmit(data: PurchaseReqestFormData): Promise<void> {
        try {
            toastId.current = toast.loading('Submitting your purchase request, please wait...', {
                isLoading: true,
                type: 'info',
                closeButton: true
            });

            data.DocumentsOwner = data.Requester;

            const result = await axios({
                method: 'POST',
                url: `/apptoo-${src}/document`,
                data: { objectType: SAPB1_PURCHASE_REQUEST_OBJECT_TYPE, document: data },
                timeout: 10000
            });

            let message = '';
            let toastType: TypeOptions = 'success';
            const { document, emailSent } = result.data;
            switch (result.status) {
                case HttpStatusCode.Created:
                    message = `Purchase request was created. DocNum: ${document.DocNum as string}. \n`;
                    if (emailSent === true) message += `Email notification was sent.`;
                    toastType = 'success';
                    break;
                case HttpStatusCode.NoContent:
                    message = `Purchase request is now waiting approval.`;
                    toastType = 'warning' as const;
                    break;
            }

            toast.update(toastId.current, {
                render: message,
                type: toastType,
                isLoading: false,
                autoClose: 5000
            });

            reset();
        } catch (error) {
            toast.update(toastId.current, {
                render: `${error as string}\n${(error as any)?.response?.data?.message as string}`,
                type: 'error',
                isLoading: false
            });
        }
    }

    function FormControlEx({ label, name, children }: { label: string; name: keyof PurchaseReqestFormData; children: React.ReactNode }) {
        return (
            <Form.Group as={Row}>
                <Form.Label column className={'pb-xxs-0 pb-lg-2'}>
                    {label}
                </Form.Label>
                <Col sm={9}>{children}</Col>
                <Form.Control.Feedback type="invalid">{errors[name]?.message}</Form.Control.Feedback>
            </Form.Group>
        );
    }

    function Message() {
        return (
            <>
                <Form.Check inline type={'radio'} value="standard" id="standard" label={'Standard'} {...register('Status')} />
                <Form.Check inline type={'radio'} value="ordered" id="ordered" label={'Ordered already'} {...register('Status')} />
                <Form.Check inline type={'radio'} value="delivered" id="delivered" label={'Ordered and delivered'} {...register('Status')} />
            </>
        );
    }

    const ACCEPTABLE_FILES = {
        'image/*': ['*.png'],
        'application/pdf': ['*.pdf']
    };

    const isStandard = watch('Status') === 'standard';
    const isOrderd = watch('Status') === 'ordered';
    const isDelivered = watch('Status') === 'delivered';

    return (
        <Container fluid="xxxl" className="gx-0">
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <h3>
                    New purchase request {src === 'live' ? ' (LIVE database) ' : ''}
                    <a href={HELP_LINK} target="_blank" rel="noreferrer">
                        <i className="bi bi-question-circle"></i>
                    </a>
                </h3>
                <br />
                <Row xs={1} sm={2} xxl={3}>
                    <Col>
                        <FormControlEx label="Requester" name="Requester">
                            <SapEmployeeTypeaheadFormControl
                                control={control}
                                name="Requester"
                                src={src}
                                size="sm"
                                placeholder={`${userContext?.name ?? ''} - ${userContext?.email ?? ''}`}
                            />
                        </FormControlEx>
                        <FormControlEx label="Series" name="Series">
                            <SAPDocumentSeriesDropdownFormControl
                                control={control}
                                name="Series"
                                src={src}
                                size="sm"
                                sapB1DocumentType={SAPB1_PURCHASE_REQUEST_OBJECT_TYPE}
                            />
                        </FormControlEx>
                        <FormControlEx label="Requested To Date" name="RequriedDate">
                            <DatePickerWithButtonValuesFormControl control={control} name="RequriedDate" size={'sm'} />
                        </FormControlEx>
                        <FormControlEx label="Subject" name="U_PHO_POSubj">
                            <SAPSubjectFormControl control={control} name="U_PHO_POSubj" />
                        </FormControlEx>
                        <FormControlEx label="Comment" name="Comments">
                            <SAPCommentFormControl control={control} name="Comments" />
                        </FormControlEx>
                    </Col>
                    <Col>
                        <FormControlEx label="Status" name="Status">
                            <Message />
                        </FormControlEx>
                        <FormControlEx label="Ordered On" name="OrderedOn">
                            <Form.Control disabled={isStandard} size={'sm'} type="date" {...register('OrderedOn')}></Form.Control>
                        </FormControlEx>
                        <FormControlEx label="Order Refference" name="OrderRef">
                            <Form.Control disabled={isStandard} size={'sm'} {...register('OrderRef')}></Form.Control>
                        </FormControlEx>
                        <FormControlEx label="Payment method" name="PaymentMethod">
                            <Form.Select disabled={isStandard} size={'sm'} {...register('PaymentMethod')}>
                                <option key={0} value={''} />
                                <option key={1} value={'PaidCC'}>
                                    Already Paid with CC
                                </option>
                                <option key={2} value={'PayOnDelivery'}>
                                    Pay On Delivery
                                </option>
                                <option key={3} value={'Proforma'}>
                                    Proforma
                                </option>
                            </Form.Select>
                        </FormControlEx>
                        <FormControlEx label="Delivered On" name="DeliveredOn">
                            <Form.Control disabled={!isDelivered} size={'sm'} type="date" {...register('DeliveredOn')}></Form.Control>
                        </FormControlEx>
                        <FormControlEx label="Attachments" name="DeliveredOn">
                            <Row>
                                <Col className="pe-0">
                                    <DropzoneFormControl
                                        control={control}
                                        name={'PurchaseOrder'}
                                        subject="Purchase Order"
                                        maxFiles={1}
                                        disabled={isStandard}
                                        minFiles={isOrderd || isDelivered ? 1 : 0}
                                        accept={ACCEPTABLE_FILES}
                                    />
                                </Col>
                                <Col className="pe-0">
                                    <DropzoneFormControl
                                        control={control}
                                        name={'DeliveryNote'}
                                        subject="Delivery Note"
                                        maxFiles={1}
                                        disabled={!isDelivered}
                                        minFiles={isDelivered ? 1 : 0}
                                        accept={ACCEPTABLE_FILES}
                                    />
                                </Col>
                                <Col>
                                    <DropzoneFormControl
                                        control={control}
                                        name={'PaymentReceipt'}
                                        subject="Payment Recepit"
                                        maxFiles={1}
                                        disabled={!isDelivered}
                                        accept={ACCEPTABLE_FILES}
                                    />
                                </Col>
                            </Row>
                        </FormControlEx>
                    </Col>
                    <Col></Col>
                </Row>
                <br />
                <Row>
                    <Form.Group as={Col}>
                        <Controller
                            control={control}
                            name="DocumentLines"
                            render={({ field: { onChange, onBlur, value }, fieldState: { invalid } }) => (
                                <SapB1MasterDataTable
                                    eligableItems="purchase"
                                    src={src}
                                    disabled={isSubmitting || isLoading}
                                    isInvalid={invalid}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    items={value}
                                    fields={[
                                        'ItemCode',
                                        'ItemDescription',
                                        'FreeText',
                                        'Quantity',
                                        'Price',
                                        '_PriceTotal',
                                        'LineVendor',
                                        'U_SFT_UCEL',
                                        'U_PHO_LinkLines',
                                        'CostingCode',
                                        'CostingCode2',
                                        'CostingCode3',
                                        'ProjectCode'
                                    ]}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">{errors.DocumentLines?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <hr className="hr" />
                <Row>
                    <Col>
                        <p>
                            Need help?{' '}
                            <a href={HELP_LINK} target="_blank" rel="noreferrer">
                                Click here
                            </a>{' '}
                            for manual.
                        </p>
                    </Col>
                    <Col className="d-flex flex-column align-items-end">
                        <SubmitButtonWithSpinner isSubmitting={isSubmitting} disabled={isSubmitting || isLoading} />
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
