/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import 'react-tabulator/css/tabulator.min.css';
import type { ReactTabulatorOptions, Tabulator } from 'react-tabulator';
import { ReactTabulator } from 'react-tabulator';
import { tabulatorColumnMap } from './TabulatorEx-columns';
import { getHeaderFilterValues, unFlattenForest, getColumnsFromFirstRow } from './TabulatorEx-helpers';
import { axios } from '../DataLoader';
import { TabulatorToolbar, TabulatorToolbarOptions } from './TabulatorToolbar';
import { useInterval } from '../Hooks/useInterval';

interface ReactTabulatorExOptionsProps extends TabulatorToolbarOptions {
    ajaxResponse?: any;
    unFlattenForest?: boolean;
    noToolbar?: boolean;
    refreshInterval?: number;
}

export default function ReactTabulatorEx({
    options,
    extendedOptions,
    onRef,
    events,
    style,
    children
}: {
    options: ReactTabulatorOptions;
    extendedOptions: ReactTabulatorExOptionsProps;
    onRef?: (ref: any) => void;
    events?: any;
    style?: any;
    children?: JSX.Element | JSX.Element[] | false;
}): JSX.Element {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const refTable = React.useRef<Tabulator>();

    function autoColumnsDefinitions(definitions: any[]): any[] {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (options?.columns) {
            definitions = options?.columns;
        }

        definitions.forEach((column) => {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (column.columns) {
                column.columns.forEach((subcolumn: any) => {
                    const mapEntry = tabulatorColumnMap.find((x) => x.field === subcolumn.field || x.field === subcolumn.template);
                    if (mapEntry !== undefined) {
                        const copyofSource = Object.assign({}, subcolumn);
                        Object.assign(subcolumn, mapEntry, copyofSource);
                    }
                    if (subcolumn?.field?.length > 0 && subcolumn.field[0] === '_') {
                        subcolumn.visible = false;
                    }
                });
            } else {
                const mapEntry = tabulatorColumnMap.find((x) => x.field === column.field || x.field === column.template);
                if (mapEntry !== undefined) {
                    const copyofSource = Object.assign({}, column);
                    Object.assign(column, mapEntry, copyofSource);
                }
                if (column?.field !== undefined && column?.field?.length > 0 && column.field[0] === '_') {
                    column.visible = false;
                }
            }
        });

        return definitions;
    }

    const defaultOptions = {
        placeholder: 'No records',
        autoColumns: true,
        clipboard: true,
        autoColumnsDefinitions,
        columns: [],
        movableColumns: true,
        // height: 'calc(100vh - 130px)',
        // maxHeight: '100%',
        persistence: {
            columns: ['visible']
        },
        persistenceMode: 'local',
        persistenceID: location.pathname.replaceAll('/', '_'),
        // layout: 'fitData',
        ajaxRequestFunc: queryRealm,
        ajaxResponse: function (url: string, params: any, response: any) {
            if (extendedOptions.ajaxResponse) {
                response = extendedOptions.ajaxResponse(url, params, response, options);
            }
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (extendedOptions?.unFlattenForest) {
                response = unFlattenForest(response);
            }

            // support for paginated responses
            const data2 = response.data == null ? response : response.data;

            const projectFilter = searchParams.get('projectCode');
            const data = data2.filter((x: any) => projectFilter == null || (projectFilter != null && x.projectCode === projectFilter));

            if (response.data == null) {
                response = data;
            } else {
                response.last_row = data.length;
                response.data = data;
            }

            const firstRowColumns = autoColumnsDefinitions(getColumnsFromFirstRow(data));
            if (!options.columns) {
                options.columns = firstRowColumns;
            }

            getHeaderFilterValues(firstRowColumns, data);
            return response;
        }
    };

    React.useEffect(() => {
        void refTable.current?.setData(options.ajaxURL, options.ajaxParams, options.ajaxConfig);
        setTimeout(function () {
            refTable.current?.redraw(true);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.ajaxURL, JSON.stringify(options.ajaxParams), JSON.stringify(options.ajaxConfig)]);

    useInterval(() => {
        void refTable.current?.blockRedraw();
        void refTable.current?.setData(options.ajaxURL, options.ajaxParams, options.ajaxConfig);
        void refTable.current?.restoreRedraw();
    }, extendedOptions.refreshInterval);

    if (options?.columns) autoColumnsDefinitions(options?.columns);
    const finalOptions: ReactTabulatorOptions = Object.assign(defaultOptions, options);

    const onTableRef = (r: any): void => {
        refTable.current = r.current;
        if (onRef) onRef(r);
    };

    async function queryRealm(url: string, config: any, _params: any): Promise<any> {
        return await new Promise(function (resolve, reject) {
            // do some async data retrieval then pass the array of row data back into Tabulator
            let promise = null;
            if (config.method === undefined || config.method.toUpperCase() === 'GET') promise = axios.get(url, { params: _params });
            if (config.method.toUpperCase() === 'POST') promise = axios.post(url, _params);
            if (promise) {
                void promise
                    .then((response) => {
                        // console.log(response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject(new Error('unknown method'));
            }
        });
    }

    return (
        <React.Fragment>
            {extendedOptions?.noToolbar !== true && (
                <TabulatorToolbar refTable={refTable} options={extendedOptions}>
                    {children}
                </TabulatorToolbar>
            )}
            <ReactTabulator style={style} onRef={onTableRef} columns={[]} options={finalOptions} events={events} />
        </React.Fragment>
    );
}
