import { useAxiosEx } from '../../DataLoader';

export interface SAPSeries {
    ObjectCode: number;
    SeriesName: string;
    Series: string;
    Remark: string;
}

export function useSapSeriesPurchaseRequests(src: string): any {
    const url = `/api-sap/db/${src}/document-series-purchase-requests`;
    const [{ data, loading, error }, refetch] = useAxiosEx(url);
    return [{ data: data ?? [], loading, error }, refetch];
}

export function useSapSeries(src: string, sapDocumentType: number) {
    const url = `/api-sap/db/${src}/document-series?ObjectCode=${sapDocumentType}`;
    const result = useAxiosEx<SAPSeries[]>(url);
    return result;
}
