import React from 'react';
import { Button, DropdownButton, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Tabulator } from 'react-tabulator\\lib\\types\\TabulatorTypes.d';
import { copyToClipboard } from './utils';
import { JIRA_BASE_URL } from './jira-helpers';

function TabulatorTableColumnDropDownButton({ refTable }: { refTable: React.MutableRefObject<Tabulator | undefined> }): JSX.Element {
    const [columnList, setColumnList] = React.useState<Tabulator.ColumnComponent[]>(refTable.current?.getColumns(true) ?? []);

    function handleColumnVisibilityChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event.target.checked) {
            refTable?.current?.showColumn(event.target.id);
        } else {
            refTable?.current?.hideColumn(event.target.id);
        }
    }

    function handleOnClick(_event: any): void {
        setColumnList(refTable.current?.getColumns(true) ?? []);
    }

    function ColumnItem(column: Tabulator.ColumnComponent): JSX.Element {
        return (
            <Form.Check
                key={column.getField()}
                className="mx-2 my-0 py-0"
                type="checkbox"
                id={column.getField()}
                label={column.getDefinition().title}
                defaultChecked={column?.isVisible()}
                onChange={handleColumnVisibilityChange}
            />
        );
    }

    return (
        <DropdownButton title="Columns" size="sm" className="me-4 d-inline" onClick={handleOnClick}>
            {columnList?.map((column: Tabulator.ColumnComponent, key: number) => {
                if (column.getSubColumns().length > 0) {
                    return (
                        <React.Fragment key={key}>
                            <Dropdown.Header key={key}>{column.getDefinition().title}</Dropdown.Header>
                            {column.getSubColumns().map((subcolumn: Tabulator.ColumnComponent) => {
                                return ColumnItem(subcolumn);
                            })}
                        </React.Fragment>
                    );
                } else {
                    return ColumnItem(column);
                }
            })}
        </DropdownButton>
    );
}

type FileNameOrCallBack = string | (() => string);

function TabulatorTableDownloadDropDownButton({
    refTable,
    filename
}: {
    refTable: React.MutableRefObject<Tabulator | undefined>;
    filename?: FileNameOrCallBack;
}): JSX.Element {
    function handleOnClickCSV(_event: any): void {
        if (typeof filename === 'function') filename = filename();
        refTable?.current?.download('csv', filename ?? 'data.csv', { delimiter: ',', bom: true });
    }

    return (
        <DropdownButton title="Download" size="sm" className="me-4 d-inline">
            <Dropdown.Item onClick={handleOnClickCSV}>CSV (experimental)</Dropdown.Item>
        </DropdownButton>
    );
}

export interface TabulatorToolbarOptions {
    noRefreshButton?: boolean;
    noColumnsButton?: boolean;
    noDownloadButton?: boolean;
    filename?: FileNameOrCallBack;
    dataSourceUrl?: string;
    jiraBulkEditSelected?: boolean;
    jiraBulkEditSelectedPPMC?: boolean;
    copyIPsToClipboard?: boolean;
}

export function TabulatorToolbar({
    refTable,
    options,
    children
}: {
    refTable: React.MutableRefObject<Tabulator | undefined>;
    options: TabulatorToolbarOptions;
    children: any;
}): JSX.Element {
    function RefreshTable(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        void refTable?.current?.replaceData();
    }

    // toto je cudne ze je tu ... ale
    function handleJiraBulkEditSelected(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        const selectedRows = refTable?.current?.getSelectedData() ?? [];
        const selectedKeys: string[] = selectedRows.map((x: any) => x.key);
        const jql = 'key in (' + selectedKeys.join(',') + ')';
        window.open(JIRA_BASE_URL + '/issues/?jql=' + jql);
        refTable?.current?.deselectRow();
    }

    function handleJiraBulkEditSelectedPPMC(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        const elements = document.querySelectorAll('[type="checkbox"]:checked');
        const issueKeys: string[] = [];
        elements.forEach((x: any) => {
            issueKeys.push(x.id);
        });
        const jql = 'key in (' + issueKeys.join(',') + ')';
        window.open(JIRA_BASE_URL + '/issues/?jql=' + jql);
        refTable?.current?.deselectRow();
    }

    function handleCopyIpsToClipboard(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        const selectedRows = refTable?.current?.getSelectedData() ?? [];
        const ips: string[] = [];
        selectedRows.forEach((x: any) => {
            const ip = x.auto_Last_known_WiFi_IP.replace('http://', '').replace('https://', '');
            ips.push(ip);
        });
        const s = ips.join('\n');
        copyToClipboard(s);
        refTable?.current?.deselectRow();
    }

    return (
        <div className="pb-2">
            {options?.noRefreshButton !== true && (
                <Button size="sm" className="me-2" onClick={RefreshTable}>
                    Refresh
                </Button>
            )}
            {options?.noColumnsButton !== true && <TabulatorTableColumnDropDownButton refTable={refTable} />}

            {options?.noDownloadButton !== true && <TabulatorTableDownloadDropDownButton refTable={refTable} filename={options.filename} />}

            {options?.dataSourceUrl !== undefined && (
                <Button href={options?.dataSourceUrl} target="_blank" className="btn btn-secondary btn-sm me-2">
                    Open Data Source
                </Button>
            )}
            {options?.jiraBulkEditSelected === true && (
                <Button onClick={handleJiraBulkEditSelected} target="_blank" className="btn btn-secondary btn-sm me-2">
                    Bulk edit selected
                </Button>
            )}
            {options?.jiraBulkEditSelectedPPMC === true && (
                <Button onClick={handleJiraBulkEditSelectedPPMC} target="_blank" className="btn btn-secondary btn-sm me-2">
                    Bulk edit selected
                </Button>
            )}

            {options?.copyIPsToClipboard === true && (
                <Button onClick={handleCopyIpsToClipboard} className="btn btn-secondary btn-sm me-2">
                    Copy IPs to Clipboard
                </Button>
            )}
            {children}
        </div>
    );
}
