import { JiraBrightpickProject } from '@bp/utils';

export function ProjectCardFooter({ project, addClassMaster = '' }: { project: JiraBrightpickProject; addClassMaster?: string }): JSX.Element {
    const IconLink = ({ href, title, icon, addClass = '' }: { href: string | null | undefined; title: string; icon: string; addClass?: string }) => {
        if (href === null) return null;
        return (
            <a className={'mx-1 ' + addClassMaster + ' ' + addClass} href={href} title={title} target="_blank" rel="noreferrer">
                <i className={'fs-5 bi ' + icon}></i>
            </a>
        );
    };

    return (
        <>
            <IconLink href={`/project/${project.projectCodeNormalized}`} title="Project Info" icon="bi-info-circle" />
            <IconLink href={`/project/${project.projectCodeNormalized}/charts`} title="Charts" icon="bi-bar-chart-line" />
            <IconLink href={project.actionList} title="Action List" icon="bi-card-checklist" />
            <IconLink href={project.equipmentmap} title="Equipment Mapping" icon="bi-hdd-network" />
            <IconLink href={project.projectFullbook} title="Fullbook" icon="bi-filetype-pdf" />
            <IconLink href={project.ganttChart} title="Gantt Chart" icon="bi-bar-chart-steps" />
            <IconLink href={`/product/runners?projectCode=${project.projectCode.value}`} title="Runners" icon="bi-robot" addClass="float-end" />
            <IconLink href={project.dashboardlink?.replace('dashboard', 'mission-control')} title="Mission Control" icon="bi-controller" addClass="float-end" />
        </>
    );
}
