import { useProjectStatsEx } from '../../../Hooks/useProjectStats';
import { PerformanceSparkline } from './PerformanceSparkLine';

interface ProjectPerformanceSparklineProps {
    projectCodeNormalized?: string;
    refreshIntervalSeconds?: number;
}

export function ProjectPerformanceSparkline({ projectCodeNormalized, refreshIntervalSeconds }: ProjectPerformanceSparklineProps) {
    const [{ data, loading }] = useProjectStatsEx(projectCodeNormalized, refreshIntervalSeconds);

    if (loading || !data?.last24hoursPerformance) return <></>;
    return (
        <>
            <PerformanceSparkline data={data.last24hoursPerformance} maxValue={data.projectData.contract_PerfLevelHour} />
        </>
    );
}
