import React from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import SubmitButtonWithSpinner from '../Components/Common/SpinnerSubmitButton';
import { axios } from '../DataLoader';
import { SapEmployeeTypeaheadFormControl } from '../Components/Common/Typeaheads/SAPEmployeeTypeahead2';
import { useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import { AuthenticatedUser } from '@bp/utils';
import classNames from 'classnames';
import ButtonHome from '../Components/Navigational/ButtonBack';

export const ReceiveSparePartsDataValidationSchema = z.object({
    requester: z.number().int(),
    docNum: z
        .number({ message: 'Delivery note number must be a number' })
        .int({ message: 'Delivery note number must be an integer' })
        .refine((value) => value.toString().length === 9, 'Must be a maximum of 9 digits'),
    signature: z.string(),
    comments: z.string().max(200).optional(),
    confirmation: z.boolean().refine((value) => value === true, { message: 'You must confirm.' })
});

export type ReceiveSparePartsData = z.infer<typeof ReceiveSparePartsDataValidationSchema>;

export default function SparePartsReceiveForm({ user }: { user: AuthenticatedUser }): JSX.Element {
    const [lastErrorMessage, setLastErrorMessage] = React.useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const signature = searchParams.get('signature') ?? '';

    const {
        register,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        control,
        formState: { errors, isSubmitting, isLoading, isValid, isSubmitSuccessful, isSubmitted }
    } = useForm<ReceiveSparePartsData>({
        resolver: zodResolver(ReceiveSparePartsDataValidationSchema),
        mode: 'onBlur',
        defaultValues: {
            requester: -1,
            docNum: undefined,
            signature,
            comments: ''
        }
    });

    async function onSubmit(data: ReceiveSparePartsData): Promise<void> {
        try {
            setLastErrorMessage(null);
            // await new Promise((resolve) => setTimeout(resolve, 5000));
            await axios({
                method: 'POST',
                url: '/process-automation/receive-spare-parts',
                data
            });

            reset();
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message ?? error.message;
            setLastErrorMessage(errorMessage);

            throw error;
        }
    }

    function FormControlEx({ label, name, children }: { label: string; name: keyof ReceiveSparePartsData; children: React.ReactNode }): JSX.Element {
        return (
            <Form.Group as={Row} controlId={name}>
                <Form.Label column className={'pb-xxs-0 pb-lg-2'}>
                    {label}
                </Form.Label>
                <Col sm={10}>{children}</Col>
                <Form.Control.Feedback type="invalid">{errors[name]?.message}</Form.Control.Feedback>
            </Form.Group>
        );
    }

    const controlDisabled = React.useMemo(() => isSubmitting || isLoading || isSubmitSuccessful, [isSubmitting, isLoading, isSubmitSuccessful]);

    // Example: Set focus to "name" on load
    React.useEffect(() => {
        setFocus('docNum');
    }, [setFocus]);

    return (
        <Container fluid="xs" className={'gx-0 container-md'} style={{ maxWidth: '800px' }}>
            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)} className="bg-light p-4 rounded border border-primary-subtle">
                <h3 className="text-primary">Confirm spare part receive</h3>
                <p className="fw-semibold">
                    In order to enlist new sparepart in your inventory, you have to confirm that you have received them. If the package is damaged or the
                    content is not as expected, please contact your supervisor.
                </p>
                <hr className="hr" />
                <p>Please follow the steps below:</p>
                <ul>
                    <li>
                        Make sure the package is not damaged, <span className="text-danger fw-bold">if the package is damaged, make a photo.</span>
                        It is not possible to upload the phone, but once you submit the form, you can send the photo as a response confirmation email
                    </li>
                    <li>
                        Check the content of the package againts the delivery note.{' '}
                        <span className="text-danger fw-bold">Report any discrepancy in the comment.</span>
                    </li>
                    <li>Leave any additional comment if needed</li>
                </ul>
                <FormControlEx label="Receiver" name="requester">
                    <SapEmployeeTypeaheadFormControl
                        control={control}
                        disabled
                        name="Requester"
                        src={'live'}
                        size="sm"
                        returnField={'EmployeeId'}
                        placeholder={`${user?.name ?? ''} - ${user?.email ?? ''}`}
                    />
                </FormControlEx>

                <FormControlEx label="Delivery Note" name="docNum">
                    <InputGroup size="sm">
                        <InputGroup.Text id="inputGroupPrepend">#</InputGroup.Text>
                        <Form.Control
                            size="sm"
                            type="number"
                            placeholder="Enter delivery note number, starting with #"
                            isInvalid={Boolean(errors.docNum)}
                            disabled={controlDisabled}
                            {...register('docNum', {
                                valueAsNumber: true // Converts the input value to a number
                            })}
                        />
                        <Form.Control.Feedback type="invalid">{errors.docNum?.message}</Form.Control.Feedback>
                    </InputGroup>
                </FormControlEx>

                <FormControlEx label="Comments" name="comments">
                    <Form.Control
                        size="sm"
                        as="textarea"
                        maxLength={200}
                        rows={5}
                        placeholder="Leave a comment if you found any discrepancy, or the package was damaged."
                        isInvalid={Boolean(errors.comments)}
                        disabled={controlDisabled}
                        {...register('comments')}
                    />
                </FormControlEx>

                <FormControlEx label="" name="confirmation">
                    <Form.Check
                        label="I confirm that I have received the package, compared it to delivery note."
                        isInvalid={Boolean(errors.confirmation)}
                        disabled={controlDisabled}
                        {...register('confirmation', {
                            required: 'Confirmation is required',
                            onChange: (e) => {
                                // Trigger validation on change
                                setValue('confirmation', e.target.checked, {
                                    shouldValidate: true // Triggers validation immediately
                                });
                            }
                        })}
                    />
                </FormControlEx>

                <hr className="hr" />
                <Row>
                    <Col>
                        {isSubmitted && (
                            <div
                                className={classNames('d-flex align-items-center', {
                                    'text-success': isSubmitSuccessful,
                                    'text-danger': !isSubmitSuccessful
                                })}
                            >
                                <i
                                    className={classNames('bi fs-2 d-inline me-2', {
                                        'bi-check-circle-fill': isSubmitSuccessful,
                                        'bi-x-circle-fill': !isSubmitSuccessful
                                    })}
                                ></i>
                                <span className="fw-bold">
                                    {isSubmitSuccessful ? (
                                        'Thank you. Message was dispatched to logistics, they will update your inventory.'
                                    ) : (
                                        <>
                                            <span>{lastErrorMessage}</span>
                                            <br />
                                            <span>Please try again or contact your supervisor.</span>
                                        </>
                                    )}
                                </span>
                            </div>
                        )}
                    </Col>
                    <Col className="ms-auto col-auto align-items-center">
                        {isSubmitSuccessful ? <ButtonHome /> : <SubmitButtonWithSpinner isSubmitting={isSubmitting} disabled={controlDisabled || !isValid} />}
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
