import { ChartArea, ChartData, ChartOptions, ScriptableContext } from 'chart.js';
import { Chart } from 'react-chartjs-2';

interface PerformanceSparklineProps {
    data: (number | null)[];
    maxValue: number | null;
}

export function PerformanceSparkline({ data, maxValue }: PerformanceSparklineProps) {
    const options: ChartOptions<'line'> = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        layout: {
            padding: 0
        },
        elements: {
            point: {
                radius: 0
            }
        },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
            x: {
                display: false
            },
            y: {
                display: false,
                min: 0,
                max: maxValue != null ? maxValue * 1.2 : undefined
            }
        }
    };

    function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
        if (area == null) return '';
        const gradient = ctx.createLinearGradient(area.left, area.bottom, area.left, area.top);
        gradient.addColorStop(0, 'green');
        gradient.addColorStop(0.7, 'orange');
        gradient.addColorStop(1, 'red');

        return gradient;
    }

    const chartData: ChartData<'line'> = {
        labels: data.map((_, i) => i),
        datasets: [
            {
                type: 'line',
                data,
                borderWidth: 1.5,
                borderColor: (ctx: ScriptableContext<'line'>) => createGradient(ctx.chart.ctx, ctx.chart.chartArea)
            }
        ]
    };

    return <Chart options={options} data={chartData} type="line" />;
}
