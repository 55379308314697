import React from 'react';
import { useSAPSalesOrder } from '../../../Hooks/SAP/useSapSalesOrder';
import { SAPDocHeader } from './SAPDocumentHeader';
import { SAPDocLines } from './SAPDocumentLines';
import { Tabulator } from 'react-tabulator';
import { getColumn } from '../../../Tabulator/TabulatorEx-columns';

interface SAPDocumentProps {
    src: string;
    DocNum: string;
    setTitle?: (title: string | undefined) => void;
}

export function SAPDocument({ src, DocNum, ...props }: SAPDocumentProps) {
    const { data } = useSAPSalesOrder(src, DocNum);

    if (!data) {
        return <div>Loading...</div>;
    }

    if (props.setTitle) {
        props.setTitle(`Sales Order ${data.header?.DocNum}`);
    }

    const columns: Tabulator.ColumnDefinition = [
        getColumn('DocVisOrder'),
        getColumn('ItemPhoto'),
        getColumn('ItemCode'),
        getColumn('ItemName'),
        getColumn('Quantity'),
        getColumn('ShipDate'),
        getColumn('ProgressPercentPositive')
    ];

    return (
        <>
            <SAPDocHeader header={data.header} />
            <SAPDocLines lines={data.lines} columns={columns} />
        </>
    );
}
