import { ReactTabulator, Tabulator } from 'react-tabulator';
import { SAPDocumentLines } from './SAPTypes';

interface SAPDocLinesProps {
    lines?: SAPDocumentLines;
    columns?: Tabulator.ColumnDefinition[] | undefined;
}

export function SAPDocLines(props: SAPDocLinesProps) {
    // prepare the options
    const options: Tabulator.Options = {
        data: props.lines,
        placeholder: 'No records',
        columns: props.columns ?? [],
        autoColumns: props.columns === undefined,
        dataTree: true,
        dataTreeChildIndent: 18,
        dataTreeElementColumn: 'ItemCode'
        //  height: 'calc(100% - 100px)'
    };

    return <ReactTabulator options={options} extendedOptions={{}} />;
}
