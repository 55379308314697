import React from 'react';
import { useUser, UserContext } from '../GoogleAuth';
import Menu from './Menu';
import AppContent from './Content';
import Alert from 'react-bootstrap/esm/Alert';

export default function Layout(): JSX.Element {
    const user = useUser();

    if (user === null) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-grow" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <UserContext.Provider value={user}>
                <Menu user={user} />
                <div className="body AppContent d-flex flex-column">
                    {/* 
                    <Alert variant={'warning'}>
                        <Alert.Heading>Maintenance Alert</Alert.Heading> We will be performing scheduled system migrations and upgrades from Friday, January 17,
                        through Sunday, January 19. During this time, you may experience temporary service disruptions. We apologize in advance for any
                        inconvenience.
                    </Alert>
                    */}
                    <AppContent user={user} />
                </div>
            </UserContext.Provider>
        </>
    );
}
