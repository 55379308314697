import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Col } from 'react-bootstrap';
import { AuthenticatedUser } from '@bp/utils';

export const UserContext = React.createContext<AuthenticatedUser | null>(null);

const JWT_KEY = 'jwt';

export async function refreshAndSaveToken(): Promise<AuthenticatedUser> {
    console.log('Refreshing token...');
    const jwt = localStorage.getItem(JWT_KEY) ?? '';
    if (jwt === '') throw new Error('No token.');
    const refreshedToken = await axios.post('/oauth/google/refresh-token', null, {
        headers: {
            Authorization: 'Bearer ' + jwt
        },
        timeout: 10000
    });
    localStorage.setItem(JWT_KEY, refreshedToken.data);
    const newUserObject = jwtDecode<AuthenticatedUser>(refreshedToken.data);
    console.log('refreshedToken: ', refreshedToken, newUserObject);
    return newUserObject;
}

export function useUser(): AuthenticatedUser | null {
    const [userToken, expirationInMinutes] = getUserLocalStorage();

    const [user, setUser] = useState<AuthenticatedUser | null>(userToken);
    const navigate = useNavigate();

    useEffect(() => {
        async function getUserDetails(): Promise<void> {
            try {
                // if the token is about to be expired or expired lets try to refresh it
                if (expirationInMinutes < 30) {
                    const newUserObject = await refreshAndSaveToken();
                    setUser(newUserObject);
                }
            } catch (error) {
                navigate('/login');
            }
        }
        void getUserDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return user;
}

export function getUserLocalStorage(): [AuthenticatedUser | null, number] {
    try {
        const jwt = localStorage.getItem(JWT_KEY) ?? '';
        const userObject = jwtDecode<AuthenticatedUser>(jwt);

        const expiresDate = new Date(userObject.exp as number);
        const deltaInMinutes = (expiresDate.getTime() - new Date().getTime()) / 60000;
        // console.log('UserObject from localStorage expires in: ', deltaInMinutes, userObject);

        if (deltaInMinutes <= 0) {
            throw new Error('Token expired.');
        }

        return [userObject, deltaInMinutes];
    } catch (error) {
        return [null, 0];
    }
}

export function LandingPage({ doLogout = false }: { doLogout: boolean }): JSX.Element {
    const [pending, setPending] = useState(false);
    const [message, setMesssage] = useState(doLogout ? 'Logout successfull.' : 'Welcome to');

    if (doLogout) {
        googleLogout();
        localStorage.clear();
    }
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const responseGoogleOnSuccess = (codeResponse: any, tenant: string) => {
        setMesssage('Please wait, login in progress ...');
        setPending(true);
        void axios
            .post(
                '/oauth/google',
                {
                    code: codeResponse.code,
                    tenant
                },
                { timeout: 2000 }
            )
            .then((tokens) => {
                setMesssage('Login successfull. Redirecting...');
                console.log('Tokens: ', tokens.data);
                localStorage.setItem(JWT_KEY, tokens.data);
                navigate('/', { replace: true });
            })
            .catch((error) => {
                console.log(error);
                setMesssage('Login failed. Contact your administrator.');
            })
            .finally(() => {
                setPending(false);
            });
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const responseGoogleOnSuccessPhotoneo = (codeResponse: any) => {
        responseGoogleOnSuccess(codeResponse, 'photoneo.com');
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const responseGoogleOnSuccessBrightpick = (codeResponse: any) => {
        responseGoogleOnSuccess(codeResponse, 'brightpick.ai');
    };

    const googleLoginPhotoneo = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: responseGoogleOnSuccessPhotoneo
    });

    const googleLoginBrightpick = useGoogleLogin({
        flow: 'auth-code',
        // scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read',
        onSuccess: responseGoogleOnSuccessBrightpick
    });

    return (
        <Container className="text-center p-3">
            <Row className="p-5">
                {pending && <Spinner className="mx-auto" animation="grow" />}
                <h2>{message}</h2>
            </Row>

            <Row>
                <Image className="mx-auto w-50" src="/Photoneo_logo_color_RGB.png" />
            </Row>
            <Row>
                <span className="fs-1 BPLogoFont BPLogoColorRed">BRIGHTP1CK</span>
            </Row>
            <Row>
                <h3>internal dashboards</h3>
                <p className="pt-5"></p>
                <Col>
                    <Button
                        className="w-50 mx-auto"
                        disabled={pending}
                        onClick={() => {
                            googleLoginBrightpick();
                        }}
                    >
                        Login as Brightpick
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="w-50 mx-auto"
                        disabled={pending}
                        onClick={() => {
                            googleLoginPhotoneo();
                        }}
                    >
                        Login as Photoneo
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
