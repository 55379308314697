import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { LandingPage } from './GoogleAuth';
import Layout from './Layout/Layout';
import './Styles/App.css';
import './Styles/Tabulator.css';
import './Styles/Formatting.css';
import './Assets/Brightpick-Regular2.ttf';

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LandingPage doLogout={false} />} />
                <Route path="/logout" element={<LandingPage doLogout={true} />} />
                <Route path="*" element={<Layout />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
